/*-------------------
      Disabled
--------------------*/

.ui.buttons .disabled.button,
.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover,
.ui.disabled.active.button {
  cursor: default;
  opacity: @buttonDisabledOpacity !important; 
  background-image: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

/* Basic Group With Disabled */
.ui.basic.buttons .ui.disabled.button {
  border-color: @disabledBorderColor;
}